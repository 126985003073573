import "@hagerty/global-header/dist/index.css";
import {
  GlobalHeader,
  PrimaryHeader,
  BrandingProps,
} from "@hagerty/global-header";
import avatar from "./img/generic-avatar.png";
import logo40thAnniversary from "../../svg/logo-40th-anniversary.svg";
import style from "./Header.module.scss";
import { useFeatureFlag } from "../../app/Providers/AppContext";

type HeaderProps = {
  firstName?: string;
  lastName?: string;
  hagertyHomeUrl: string;
  logoutUrl: string;
};

export const Header = ({
  firstName = "",
  lastName = "",
  hagertyHomeUrl,
  logoutUrl,
}: HeaderProps) => {
  const { FORTIETH_LOGO } = useFeatureFlag();
  const branding: BrandingProps = {
    href: hagertyHomeUrl,
    src: FORTIETH_LOGO.treatment === "new" ? logo40thAnniversary : undefined,
  };

  const userMenuOptions = [<a href={logoutUrl}>Log out</a>];

  const user = {
    firstName,
    lastName,
    avatar,
  };

  return (
    <GlobalHeader sticky className={style.container}>
      <PrimaryHeader
        branding={branding}
        user={user}
        userMenuOptions={userMenuOptions}
      />
    </GlobalHeader>
  );
};
