import { partners } from "@hagerty/self-service-components/api/types";
import { FeatureFlagOptions, FeatureFlags } from ".";

export const defaultFeatureFlags: FeatureFlags = {
  WALLET: { treatment: "off", config: null },
  ADD_VEHICLE: { treatment: "base", config: null },
  MEMBER_GRAPH: { treatment: "off", config: null },
  REMOVE_VEHICLE: { treatment: "off", config: { max_vehicle_count: "10" } },
  FORTIETH_LOGO: { treatment: "base", config: null },
  ENTHUSIAST: { treatment: "off", config: null },
  CIG: { treatment: "base", config: null },
};

/** An array of all supported feature flags. */
export const featureFlagKeys = Object.keys(defaultFeatureFlags);

/**
 * Convert a featureFlags object into an array of strings formatted as "key:value"
 * ex: { INSURANCE: "new"} => ["INSURANCE:new"]
 */
export const formatFeatureFlagsForTracking = (
  featureFlags: FeatureFlags
): string[] => {
  return Object.entries(featureFlags).map(
    ([key, value]) => `${key}:${value.treatment}`
  );
};

export const defaultTestFeatureFlags = {
  WALLET: { treatment: "off", config: null },
  ADD_VEHICLE: { treatment: "base", config: null },
  MEMBER_GRAPH: { treatment: "off", config: null },
  REMOVE_VEHICLE: { treatment: "off", config: { max_vehicle_count: "10" } },
  FORTIETH_LOGO: { treatment: "base", config: null },
  ENTHUSIAST: { treatment: "off", config: null },
  CIG: { treatment: "base", config: null },
} as const;

const hagerty = "off";
const sso = "sso";
const LoginFeatureFlags = [hagerty, sso];
const WalletFeatureFlags = ["off", "on"];
const AddVehicleFeatureFlags = ["base", "new"];
const MemberGraphFeatureFlags = ["off", "on"];
const RemoveVehicleFeatureFlags = ["off", "on"];
const FortiethLogoFeatureFlags = ["base", "new"];
const EnthusiastFeatureFlags = ["off", "on"];
const CigFeatureFlags = ["base", "new"];

export const defaultPartnerOptions: FeatureFlagOptions = {
  values: ["off", ...partners],
  defaultValue: "off",
  config: null,
} as const;

export const defaultLoginOptions: FeatureFlagOptions = {
  values: LoginFeatureFlags,
  defaultValue: hagerty,
  config: null,
} as const;

export const defaultWalletOptions: FeatureFlagOptions = {
  values: WalletFeatureFlags,
  defaultValue: "off",
  config: null,
} as const;

export const defaultAddVehicleOptions: FeatureFlagOptions = {
  values: AddVehicleFeatureFlags,
  defaultValue: "base",
  config: null,
} as const;

export const defaultMemberGraphOptions: FeatureFlagOptions = {
  values: MemberGraphFeatureFlags,
  defaultValue: "off",
  config: null,
} as const;

export const defaultRemoveVehicleOptions: FeatureFlagOptions = {
  values: RemoveVehicleFeatureFlags,
  defaultValue: "off",
  config: { max_vehicle_count: "10" },
} as const;

export const defaultPolicyDetailsOptions: FeatureFlagOptions = {
  values: ["off", "on"],
  defaultValue: "off",
  config: null,
} as const;

export const defaultFortiethLogoOptions: FeatureFlagOptions = {
  values: FortiethLogoFeatureFlags,
  defaultValue: "base",
  config: null,
} as const;

export const defaultEnthusiastOptions: FeatureFlagOptions = {
  values: EnthusiastFeatureFlags,
  defaultValue: "off",
  config: null,
} as const;

export const defaultCigOptions: FeatureFlagOptions = {
  values: CigFeatureFlags,
  defaultValue: "base",
  config: null,
} as const;
