import { useEffect } from "react";
import { SectionHash } from "../app/Providers/ActiveSectionContext";

// TODO: This doesn't work right for the #settings hash because it's at the bottom and the other item's loading status
// changes during scroll, which leads to an incorrect scroll position.
// Ideally, scrollIntoView would be called once at the root after all loading is complete (to assure the scrollIntoView works right).
// But to do that, we would need each section to report that its loading is complete, perhaps via a new loadingStatusContext.
// Thus, likely not worth the effort. Alternatively, could check DOM for "Loading..." and when none are found, trigger this.
// Ticket: https://dev.azure.com/hagerty/Hagerty/_workitems/edit/79595
export function useScrollToHashOnLoad(target: SectionHash) {
  useEffect(
    function scrollToHashOnLoad() {
      const { hash } = window.location;
      if (hash === `#${target}`) {
        const element = document.querySelector(hash);
        if (element) element.scrollIntoView();
      }
    },
    [target]
  );
}
