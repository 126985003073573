import { useState } from "react";
import { Address } from "../Address";
import { useDialog } from "../Dialog";
import { Card } from "../Card";
import { Link } from "../Link";
import styles from "./AccountSettings.module.scss";
import {
  AccountSettingsDialog,
  logContactInformationModal,
  SourceType,
} from "./AccountSettingsDialog";
import { StateFarmHelpDialog } from "./StateFarmHelpDialog";
import {
  Customer,
  MutableCustomer,
  formatPhoneNumber,
  getDisplayName,
  phoneNumbers,
} from "shared";
import { Status } from "./AccountSettings.types";
import { useBeforeunload } from "react-beforeunload";
import { Alert } from "../Alert";
import { useScrollToHashOnLoad } from "../../hooks/useScrollToHashOnLoad";
import { NotProvided } from "./NotProvided";
import { CommunicationPreferences } from "./CommunicationPreferences";
import { useFeatureFlag, useUrls } from "../../app/Providers/AppContext";
import {
  useIsStateFarmPartitioned,
  useIsSso,
} from "../../app/Providers/AppContext";
import { useIsHdcMember } from "../../app/Providers/DriversClubContext";
import { mt } from "../../utils/tracking";

type AccountSettingsProps = {
  initialCustomer: Customer;
};

/** Renders the "Account settings" region */
export const AccountSettings = ({ initialCustomer }: AccountSettingsProps) => {
  const urls = useUrls();
  const { isHdcMember } = useIsHdcMember();

  useScrollToHashOnLoad("settings");
  const [dialogStatus, setDialogStatus] = useState<Status>("ok");
  const [customer, setCustomer] = useState<Customer>(initialCustomer);

  const editDialog = useDialog();
  const stateFarmDialog = useDialog();

  const [sourceType, setSourceType] = useState<SourceType | null>(null);

  const isSso = useIsSso();
  const hasStateFarmPartition = useIsStateFarmPartitioned();
  const isStateFarmSsoLogin = hasStateFarmPartition && isSso;

  const { WALLET } = useFeatureFlag();
  const isWalletFeatureFlagOn = WALLET.treatment === "on";
  const isEligibleCustomerForWallet =
    !isSso && (hasStateFarmPartition || isHdcMember);

  // Notify the user of unsaved changes if they close the window before the save is complete
  useBeforeunload((event: any) => {
    if (dialogStatus === "pending") {
      event.preventDefault();
      // Most browsers won't show this custom message, but setting here for those that do.
      return "Save in progress";
    }
  });

  const openEditDialog = (type: SourceType) => {
    logContactInformationModal(type);
    setSourceType(type);
    editDialog.open();
  };

  const closeEditDialog = () => {
    mt.trackPopupClose();
    editDialog.close();
  };

  const openStateFarmDialog = () => {
    mt.trackPopup("state farm");
    stateFarmDialog.open();
  };

  const closeStateFarmDialog = () => {
    mt.trackPopupClose();
    stateFarmDialog.close();
  };

  // The mutate is slow (around 5 seconds). So, do an optimistic update to improve perceived performance
  async function onSubmit(mutableCustomer: MutableCustomer) {
    setDialogStatus("pending");

    // Copy current customer record before optimistically updating in case we need to roll back when the save fails
    const oldCustomer = customer;

    // Since mutable customer only has some of the necessary properties, blend with existing full customer
    const newCustomer = { ...customer, ...mutableCustomer };

    // Optimistically update the app's UI to show the new record, even though it hasn't been saved yet (to avoid drawing attention to the slow call)
    setCustomer(newCustomer);

    // Close the dialog immediately when the form is submitted.
    // This avoids "locking" the user into the modal while the save is in progress.
    // We have comprehensive client-side validation, so we can be confident the save will
    // succeed if client-side validation passes.
    closeEditDialog();

    try {
      const resp = await fetch(`${window.location.origin}/api/customer`, {
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(mutableCustomer),
      });
      if (!resp.ok) throw resp;
      // Although the dialog was already hidden, setting this status is important in case someone reopens the dialog.
      setDialogStatus("ok");
      return true;
    } catch {
      // If the save fails, show the old data again...
      setCustomer(oldCustomer);

      // And display an error message
      setDialogStatus("mutateError");
      return false;
    }
  }

  const displayName = getDisplayName(customer);
  const { line1, line2, city, state, postalCode, phone } = customer;

  function renderDemographicInfoForm() {
    const showHelpDialogOnEdit = hasStateFarmPartition;
    return (
      <>
        <dl>
          <div className={styles.rowWrapperName}>
            <dt className={styles.descriptionText}>Name</dt>
            <dd>{displayName}</dd>
          </div>

          {!isStateFarmSsoLogin && (
            <div className={styles.rowWrapper}>
              <dt className={styles.descriptionText}>Login settings</dt>
              <dd>
                <Link
                  className={styles.editLink}
                  ariaLabel="Manage login settings"
                  href={urls.account.login}
                >
                  Manage settings
                </Link>
              </dd>
            </div>
          )}
          <div className={styles.rowWrapper}>
            <dt className={styles.descriptionText}>Mailing address</dt>
            <dd className={styles.infoWithLink}>
              <div className={`with-button ${styles.withButton}`}>
                <Address
                  line1={line1}
                  line2={line2}
                  city={city}
                  state={state}
                  postalCode={postalCode}
                  fallback={<NotProvided />}
                />
                <button
                  aria-label="Edit contact information"
                  className={styles.buttonLink}
                  onClick={
                    showHelpDialogOnEdit
                      ? () => openStateFarmDialog()
                      : () => openEditDialog("mailing address")
                  }
                >
                  Edit
                </button>
              </div>
            </dd>
          </div>

          <div className={styles.rowWrapper}>
            <dt id="phone-number-header" className={styles.descriptionText}>
              Phone number
            </dt>
            <dd className={styles.infoWithLink}>
              <div className={`with-button ${styles.withButton}`}>
                {phone ? (
                  <span aria-labelledby="phone-number-header">
                    {formatPhoneNumber(phone)}
                  </span>
                ) : (
                  <NotProvided />
                )}
                <button
                  aria-label="Edit contact information"
                  className={styles.buttonLink}
                  onClick={
                    showHelpDialogOnEdit
                      ? () => openStateFarmDialog()
                      : () => openEditDialog("phone number")
                  }
                >
                  Edit
                </button>
              </div>
            </dd>
          </div>

          {isWalletFeatureFlagOn && isEligibleCustomerForWallet && (
            <div className={styles.rowWrapper}>
              <dt className={styles.descriptionText}>Payment methods</dt>
              <dd>
                <Link className={styles.editLink} href={urls.account.wallet}>
                  Manage wallet
                </Link>
              </dd>
            </div>
          )}

          <CommunicationPreferences styles={styles} />
        </dl>
        {editDialog.isOpen && sourceType && (
          <AccountSettingsDialog
            sourceType={sourceType}
            status={dialogStatus}
            customer={customer}
            onSubmit={onSubmit}
            onCloseDialog={closeEditDialog}
          />
        )}

        {stateFarmDialog.isOpen && (
          <StateFarmHelpDialog onDismiss={closeStateFarmDialog} />
        )}

        {dialogStatus === "pending" && (
          <Alert
            ariaLabel="Saving contact information"
            className={styles.alert}
            message="Saving..."
            level="info"
          />
        )}

        {dialogStatus === "mutateError" && (
          <Alert
            className={styles.alert}
            onClose={() => setDialogStatus("ok")}
            closeButtonAriaLabel="Close error message"
            showIcon
            message={
              <div className={styles.message}>
                <div>
                  Sorry, we are unable to update your information at this time.
                  Please try again or call{" "}
                  <Link phoneNumber={phoneNumbers.auto} /> for assistance.
                </div>
              </div>
            }
            level="danger"
          />
        )}
      </>
    );
  }

  function renderCommunicationPreferences() {
    return (
      <dl>
        <CommunicationPreferences styles={styles} />
      </dl>
    );
  }

  return (
    <Card width="full" className={styles.container}>
      {/* State Farm partner via SSO login, without HDC cannot modify demographic settings */}
      {!isHdcMember && isStateFarmSsoLogin
        ? renderCommunicationPreferences()
        : renderDemographicInfoForm()}
    </Card>
  );
};
