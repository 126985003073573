// Call a provided callback when scrolling completes.
export function callAfterScrollingCompletes(callback: () => void): void {
  let prevPosition: null | number = null;

  checkScrollPosition();

  function checkScrollPosition() {
    // When two positions in a row have the same scroll position,
    // then scrolling is considered complete, and the provided callback is called.
    if (window.scrollY === prevPosition) {
      callback();
    } else {
      prevPosition = window.scrollY;
      // Poll for scroll position every 250ms.
      setTimeout(checkScrollPosition, 250);
    }
  }
}
