import { phoneNumbers } from "shared";
import { TagAction } from "../../types/Tag.types";
import { Alert } from "../Alert";
import { Link } from "../Link";
import styles from "./CallDriversClubSupport.module.scss";

const upgradeCancelPhoneTagAction: TagAction = {
  actionName: "upgrade_cancel_phone",
  actionType: "link",
};

/** Renders a link to upgrade or cancel a policy */
export const CallDriversClubSupport = () => {
  const upgradeMessage = "Need help with your membership?";
  return (
    <Alert
      className={styles.root}
      level="default"
      message={<div className={styles.message}>{upgradeMessage}</div>}
      actionSlot={
        <Link
          tagAction={upgradeCancelPhoneTagAction}
          phoneNumber={phoneNumbers.hdc}
        >
          Call {phoneNumbers.hdc}
        </Link>
      }
    />
  );
};
