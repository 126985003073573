import {
  SectionHash,
  useActiveSectionContext,
} from "../app/Providers/ActiveSectionContext";
import useEventListener from "@use-it/event-listener";

// Anytime a target's "top" is less than this value, it's considered active.
const targetTop = 150;

/** Sets link as active based on scroll position.
 * @param elementRef Element whose position should be monitored to determine if it's active. The element is considered active if its top < targetTop (declared above).
 * @param hash Hash to set in URL when section is active.
 * */
export function useActiveSection(elementRef: any, hash: SectionHash) {
  const { setActiveSection, monitorScroll } = useActiveSectionContext();

  // TODO: Throttle this call for perf. Ticket: https://dev.azure.com/hagerty/Hagerty/_workitems/edit/79597
  useEventListener("scroll", () => {
    if (!monitorScroll.current) return; // Nothing to do if monitor scroll is disabled (it's disabled briefly by SubNav when a link is clicked)
    const el = elementRef.current;
    if (!el) return;
    const { top } = el.getBoundingClientRect();
    const shouldBeActive = top >= 0 && top < targetTop;
    if (shouldBeActive) setActiveSection(hash);
  });
}
