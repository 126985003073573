import { CorePolicy } from "@hagerty/self-service-components/api/types";
import cx from "clsx";
import styles from "./PolicyTiles.module.scss";
import { IdCardTile } from "./IdCardTile";
import { ClaimsTile } from "./ClaimsTile";
import { BillingTile } from "./BillingTile";
import { DocumentsTile } from "./DocumentsTile";

type PolicyProps = {
  policy: CorePolicy;
  className?: string;
};

export const PolicyTiles = ({ policy, className }: PolicyProps) => {
  return (
    <div className={cx(styles.policyTiles, className)}>
      <IdCardTile policy={policy} />
      <ClaimsTile policy={policy} />
      <BillingTile policy={policy} />
      <DocumentsTile policy={policy} />
    </div>
  );
};
