import { StateName } from ".";

type Headings = {
  heading: string;
  accessibleHeading: string;
};

export const partnerMap: Record<string, Headings> = {
  "state-farm": {
    heading: "State Farm Classic+®",
    accessibleHeading: "State Farm Classic Policy",
  },
};

export function getDefaultPartnerHeadings(stateName: StateName): Headings {
  const heading = `${stateName} Policy`;
  return {
    heading,
    accessibleHeading: heading,
  };
}

export const getPartnerHeadings = (
  partner: string | undefined = "",
  stateName: StateName
): Headings => {
  return partnerMap[partner] ?? getDefaultPartnerHeadings(stateName);
};

export const getEnthusiastHeadings = (): Headings => ({
  heading: "Enthusiast+ policy",
  accessibleHeading: "Enthusiast+ Policy",
});
