import { Card, Popover } from "@hagerty/self-service-components";
import { useRef, useState } from "react";
import { useUrls } from "../../../app/Providers/AppContext";
import { Tile } from "../../Tile";
import { Billing } from "../../../svg";
import styles from "./PolicyTiles.module.scss";
import { ReactComponent as DownChevron } from "../../../svg/chevron-down.svg";
import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { Link } from "../../Link";
import { TagAction } from "../../../types/Tag.types";

type BillingTileProps = {
  policy: CorePolicy;
};

const payBillTagAction: TagAction = {
  actionType: "link",
  actionName: "Pay a bill",
};

export const BillingTile = ({ policy }: BillingTileProps) => {
  const [redirect, setRedirect] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const { country } = policy;
  const urls = useUrls();

  const hidePopover = () => {
    setIsPopOverOpen(false);
    triggerRef?.current?.blur();
  };

  const onClickLink = () => {
    hidePopover();
    setRedirect(true);
  };

  return (
    <>
      <Tile
        icon={<img src={Billing} alt="Billing" aria-hidden="true" />}
        className={styles.tile}
        cta="Billing"
        onClick={() => setIsPopOverOpen(!isPopOverOpen)}
        ctaIcon={<DownChevron />}
        ref={triggerRef}
        isLoading={redirect}
      />
      <Popover
        triggerRef={triggerRef}
        onClose={() => setTimeout(hidePopover)}
        isOpen={isPopOverOpen}
        placement={"bottom start"}
        className={styles.popover}
        restoreFocus={false}
      >
        <Card className={styles.card}>
          <Link
            tagAction={payBillTagAction}
            onClick={onClickLink}
            href={urls.billing.payBill(country)}
          >
            Pay a bill
          </Link>
        </Card>
      </Popover>
    </>
  );
};
