import { Alert, Button, ListCard } from "@hagerty/self-service-components";
import styles from "./CoveragesListCard.module.scss";
import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { useUrls } from "../../../app/Providers/AppContext";
import { TagAction } from "../../../types/Tag.types";
import { Link } from "../../Link";
import { getStateName, phoneNumbers } from "shared";
import { useState } from "react";
import { mt } from "../../../utils/tracking";

export type CoveragesListCardProps = {
  policy: CorePolicy;
};

const editCoveragesTagAction: TagAction = {
  actionName: "Edit coverages",
  actionType: "button",
};

const AlertDesc = () => (
  <>
    This doesn’t cover damages while on the road. To modify or add coverages,
    please contact Hagerty’s Member Service Center at{" "}
    <Link className={styles.phoneLink} phoneNumber={phoneNumbers.auto}>
      {phoneNumbers.auto}
    </Link>
    .
  </>
);

export const CoveragesListCard = ({ policy }: CoveragesListCardProps) => {
  const [redirectEditCoverages, setRedirectEditCoverages] = useState(false);
  const urls = useUrls();
  const { policyCoverages, state, country, hasIdCard } = policy;

  const onClickEditCoverages = () => {
    mt.trackAction(editCoveragesTagAction);
    setRedirectEditCoverages(true);
  };

  return (
    <ListCard>
      <ListCard.Header>Policy Coverages</ListCard.Header>
      <ListCard.ExpandableRows
        maxRowCount={5}
        viewRowsLabel="View coverages"
        hideRowsLabel="Hide coverages"
        hasFooter={hasIdCard}
      >
        {!hasIdCard && (
          <Alert
            className={styles.compOnlyAlert}
            variant="info"
            title={`You only have Comprehensive coverage for your ${getStateName(
              state
            )} policy.`}
            desc={<AlertDesc />}
          />
        )}
        {policyCoverages.map(({ Name, Limit }, index) => (
          <ListCard.Row key={index}>
            <div className={styles.row}>
              <div className={styles.name}>{Name}</div>
              <div className={styles.value}>{Limit}</div>
            </div>
          </ListCard.Row>
        ))}
      </ListCard.ExpandableRows>

      {hasIdCard && (
        <ListCard.Footer>
          <Button
            className={styles.editCoveragesButton}
            as="a"
            size="s"
            variant="outlined"
            aria-label="Edit coverages"
            href={urls.insurance.editCoverages(country)}
            onClick={onClickEditCoverages}
            isLoading={redirectEditCoverages}
          >
            Edit coverages
          </Button>
        </ListCard.Footer>
      )}
    </ListCard>
  );
};
