import { CorePolicy } from "@hagerty/self-service-components/api/types";
import { TLD, URLs } from "shared";

export const getTld = (hostname: string): TLD => {
  const tld = hostname.split(".").pop();
  if (tld === "localhost") return "com";
  if (tld === "com" || tld === "ca") return tld;

  throw new Error(`unknown tld: ${tld}`);
};

/**
 * Wraps window.history.replaceHash, and sets the proper base URL when changing the hash.
 * Necessary because the full URL must be specified because index.html is served under a subdomain that doesn't
 * match the browser's base URL.
 * */
export function replaceUrlHash(hash?: string): void {
  const hashOrEmpty = hash ? `#${hash}` : "";
  window.history.replaceState(
    null,
    "",
    `${window.location.origin}${hashOrEmpty}`
  );
}

export const getFileClaimUrl = (policy: CorePolicy, urls: URLs): string => {
  const { country, partner, policyNumber } = policy;
  if (partner == "state-farm") {
    return urls.stateFarm.claim;
  }

  return country === "USA"
    ? urls.insurance.newFileClaim(country)
    : urls.insurance.fileClaim(policyNumber, country);
};
