import { useEffect } from "react";
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import { callAfterScrollingCompletes } from "../../utils/scrollUtils";
import { replaceUrlHash } from "../../utils/urlUtils";

const sectionHashes = ["billing", "insurance", "hdc", "settings"] as const;

export type SectionHash = typeof sectionHashes[number];

export const defaultSectionHash: SectionHash = "billing";

type ActiveSectionContext = {
  /** Stores which section is active. */
  activeSection: SectionHash | undefined;

  /** Set active section. */
  setActiveSection: (section: SectionHash) => void;

  /** Set to true to change active section when scrolling */
  monitorScroll: MutableRefObject<boolean>;
};

const Context = createContext<ActiveSectionContext | null>(null);

type ActiveSectionContextProviderProps = {
  children: ReactNode;
};

// If a valid hash is in the URL on page load, use it to set the initial active section.
function getInitialActiveSection() {
  const hash = window.location.hash.substr(1); // remove # prefix;
  return sectionHashes.find((h) => h === hash);
}

const initialActiveSection = getInitialActiveSection();

/** Provider for Active Section context */
export const ActiveSectionContextProvider = ({
  children,
}: ActiveSectionContextProviderProps) => {
  // Active section is undefined on load.
  const [activeSection, setActiveSection] = useState<SectionHash>(
    initialActiveSection ?? defaultSectionHash
  );
  // This is normally true, but is disabled for a moment when the user clicks on a subnav link to avoid
  // the scroll-based active section logic from conflicting with the hash's scroll.
  // Without this, when the user clicks on a subnav link, the scroll to the hash might be stopped
  // prematurely by useActiveSection's scroll handler "seeing" a header that's in an "active" position before
  // the scroll is completed.
  // This is set to false initially if there's an initial hash in the URL on load
  // in order to allow time for the initial scroll to the hash to complete.
  // This is a ref because it need not trigger a re-render.
  const monitorScroll = useRef(initialActiveSection === undefined);

  useEffect(() => {
    if (initialActiveSection) {
      callAfterScrollingCompletes(() => (monitorScroll.current = true));
    }
  }, []);

  const value = {
    activeSection,
    setActiveSection: (hash: SectionHash) => {
      if (hash === activeSection) return;
      setActiveSection(hash);
      replaceUrlHash(hash);
    },
    monitorScroll,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

/** Hook to consume and set activeSection via context */
export function useActiveSectionContext() {
  const context = useContext(Context);
  if (context) return context;
  throw new Error(
    "useActiveSectionContext must be wrapped with <ActiveSectionContextProvider />"
  );
}
